<template>
  <div class="BannerEditMode">
    <div class="Container">
      <p>
        {{ $t('banner.editMode.title') }} {{ labelTitle }}
        <HaIcon
          :icon="editMode ? faWrench : faRocket"
          aria-hidden="true"
        />
      </p>
      <a
        :href="link"
        data-ux="Explore_OrganizationPublicPage_BannerEditMode_SwitchMode"
      >
        {{ labelCta }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { faWrench, faRocket } from '@fortawesome/pro-solid-svg-icons'
import { computed, useRoute, useI18n } from '#imports'
import { HaIcon } from '@ha/components-v3'
import useEditMode from '@/composables/useEditMode'

const i18n = useI18n()
const route = useRoute()
const { editMode } = useEditMode()

const labelTitle = computed(() => {
  return editMode.value
    ? i18n.t('banner.editMode.mode.edit')
    : i18n.t('banner.editMode.mode.public')
})
const labelCta = computed(() => {
  return editMode.value
    ? i18n.t('banner.editMode.cta.public')
    : i18n.t('banner.editMode.cta.edit')
})
const link = computed(() => {
  return editMode.value
    ? `/associations/${route.params.organization}`
    : `/associations/${route.params.organization}/administration/ma-page-publique`
})
</script>

<style lang="scss">
.BannerEditMode {
  padding-top: $ha-spacing-small;
  padding-bottom: $ha-spacing-small;
  text-align: center;
  background-color: var(--ha-color-text);

  > .Container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mediaQuery(600) {
      flex-direction: row;
      justify-content: space-between;
    }

    > p {
      font-size: $ha-font-size-small;
    }
  }

  p {
    margin: 0;
    color: var(--ha-color-white);
    font-weight: $ha-font-weight-semibold;

    > svg {
      margin-left: $ha-spacing-mini;
    }
  }

  a {
    margin-top: $ha-spacing-small;
    padding: $ha-spacing-tiny $ha-spacing-large;
    color: var(--ha-color-white);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-tiny;
    background-color: transparent;
    border: $ha-border-width-regular solid var(--ha-color-white);
    border-radius: $ha-radius-regular;

    @include transition-base(
      (color, background-color, border-color, box-shadow)
    );

    @include mediaQuery(600) {
      margin-top: 0;
      font-size: $ha-font-size-small;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 ($ha-unit * 0.5) set-alpha('white', 0.25);
    }

    &:hover,
    &:active {
      color: var(--ha-color-text);
      background-color: var(--ha-color-white);
      border-color: var(--ha-color-white);
    }
  }
}
</style>
