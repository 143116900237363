<template>
  <div>
    <h4>{{ $t('organization.contact.socials.title') }}</h4>
    <SocialNetwork
      v-for="network in socialNetwork"
      :key="network"
      :type="network"
    />
  </div>
</template>

<script setup lang="ts">
import SocialNetwork from '@/components/Organization/Partials/SocialNetwork.vue'

const socialNetwork = [
  'webSite',
  'facebookPage',
  'twitterPage'
]
</script>
