<template>
  <li class="MediaList--Item">
    <HaValidatorForm v-slot="{ valid }">
      <div
        v-if="showVideoUploader"
        class="MediaList--Upload MediaList--Upload-Video"
      >
        <HaValidatorInput
          v-slot="{ validated, errors }"
          name="newVideoUrl"
          :model-value="newVideoUrl"
          rules="required|url"
          slim
        >
          <HaFormField :is-valid="validated ? valid : null">
            <template #label>
              {{ $t('organization.carousel.videoUrl') }}
            </template>
            <HaInput
              v-model="newVideoUrl"
              name="newVideoUrl"
              :is-valid="validated ? valid : null"
              placeholder="https://www.youtube.com/watch?v="
            />
            <template #error>
              {{ errors[0] }}
            </template>
          </HaFormField>
        </HaValidatorInput>
        <div class="MediaList--Actions">
          <ha-button
            color="basic"
            variant="flat"
            data-ux="Explore_OrganizationPublicPage_EditMode_Carousel_CancelNewVideo"
            @click="cancel()"
          >
            {{ $t('button.cancel') }}
          </ha-button>
          <ha-button
            :loading="isLoading"
            :disabled="!valid || isLoading"
            data-ux="Explore_OrganizationPublicPage_EditMode_Carousel_SaveNewVideo"
            @click="save()"
          >
            {{ $t('button.add') }}
          </ha-button>
        </div>
      </div>
      <button
        v-else
        class="MediaList--Button"
        data-ux="Explore_OrganizationPublicPage_EditMode_Carousel_AddNewVideo"
        @click="showVideoUploader = true"
      >
        <HaIcon :icon="faVideo" />
        {{ $t('organization.carousel.addVideo') }}
      </button>
    </HaValidatorForm>
  </li>
</template>

<script>
import { faVideo } from '@fortawesome/pro-solid-svg-icons'
import {
  HaButton,
  HaFormField,
  HaInput,
  useNotifications,
  HaValidatorInput,
  HaValidatorForm,
  useTracking,
  HaIcon
} from '@ha/components-v3'
import { useOrganizationStore } from '@/store/organization.store'

export default {
  name: 'AddVideo',
  components: {
    HaValidatorForm,
    HaValidatorInput,
    HaButton,
    HaFormField,
    HaInput,
    HaIcon
  },
  setup() {
    const { tracking } = useTracking()
    const { pushNotification } = useNotifications()
    const organizationStore = useOrganizationStore()

    return { tracking, pushNotification, organizationStore }
  },
  data() {
    return {
      isLoading: false,
      showVideoUploader: false,
      newVideoUrl: null,
      faVideo
    }
  },
  computed: {
    organization() {
      return this.organizationStore.organization
    },
    hasMedia() {
      return !!this.organization?.galleryImages
    }
  },
  methods: {
    cancel() {
      this.newVideoUrl = null
      this.showVideoUploader = false
    },
    save() {
      this.isLoading = true

      this.organizationStore
        .addVideo({
          slug: this.organization.organizationSlug,
          url: this.newVideoUrl
        })
        .then(() => {
          this.newVideoUrl = null
          this.showVideoUploader = false

          this.tracking.track('Organization Page Updated', {
            organization_slug: this.organization.organizationSlug,
            update_type: ['Ajout Vidéo']
          })

          this.pushNotification({
            type: 'success',
            title: this.$t('organization.carousel.add.video.title'),
            body: this.$t('organization.carousel.add.video.body'),
            timeout: 5000
          })
        })
        .catch((err) => {
          const status = err?.response.status

          this.errorUpload = this.$t(`error.code.${status}.message`)

          this.pushNotification({
            type: 'danger',
            title: this.$t('error.upload'),
            body: this.$t(`error.code.${status}.message`),
            timeout: 5000
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
