<template>
  <figure class="Logo">
    <div class="LogoWrapper">
      <ImageWithFallback
        :src="logo"
        :size="{
          width: 140,
          height: 140
        }"
        class="Logo--Image"
        data-test-id="LogoImage"
        alt="Logo"
      >
        <span class="Logo--Empty">
          <HaIcon :icon="faUsers" />
        </span>
      </ImageWithFallback>

      <template v-if="editMode">
        <button
          class="Logo--Upload"
          data-ux="Explore_OrganizationPublicPage_EditMode_Headline_Logo_Add"
          @click="displayModalUpload = true"
        >
          <HaIcon :icon="faCamera" />
          <span class="visually-hidden">
            {{ $t('organization.headline.logo.upload') }}
          </span>
        </button>
        <ha-button
          v-if="logo"
          data-ux="Explore_OrganizationPublicPage_EditMode_Headline_Logo_Delete"
          class="Logo--Delete"
          color="danger"
          @click="deleteLogo"
        >
          <HaIcon :icon="faTrash" />
          <span class="visually-hidden">
            {{ $t('organization.headline.logo.delete') }}
          </span>
        </ha-button>
      </template>
    </div>
    <ModalUpload
      v-if="displayModalUpload"
      :media="logo"
      :max-width="140"
      :max-height="140"
      :action="'updateLogo'"
      file-name="logo"
      @close="displayModalUpload = false"
    />
  </figure>
</template>

<script>
import { get } from 'lodash-es'
import {
  faUsers,
  faCamera,
  faTrash
} from '@fortawesome/pro-solid-svg-icons'
import {
  HaButton,
  HaIcon,
  useTracking,
  useNotifications
} from '@ha/components-v3'
import ModalUpload from '@/components/Modal/ModalUpload.vue'
import ImageWithFallback from '@/components/ImageWithFallback/ImageWithFallback'
import useEditMode from '@/composables/useEditMode'
import { useOrganizationStore } from '@/store/organization.store'

export default {
  name: 'Logo',
  components: {
    HaButton,
    ModalUpload,
    ImageWithFallback,
    HaIcon
  },
  setup() {
    const { pushNotification } = useNotifications()
    const { tracking } = useTracking()
    const { editMode } = useEditMode()
    const organizationStore = useOrganizationStore()

    return { pushNotification, tracking, editMode, organizationStore }
  },

  data() {
    return {
      displayModalUpload: false,
      hasLogoLoaded: false,
      hasLogoError: false,
      faUsers,
      faCamera,
      faTrash
    }
  },
  computed: {
    isLogoDisplayed() {
      return this.logo && this.hasLogoLoaded && !this.hasLogoError
    },
    organization() {
      return this.organizationStore.organization
    },
    logo() {
      return this.organization?.logo
    }
  },
  methods: {
    setLogoLoad() {
      this.hasLogoLoaded = true
    },
    setLogoError() {
      this.hasLogoError = true
    },
    async deleteLogo() {
      try {
        await this.organizationStore.deleteLogo(
          this.organization.organizationSlug
        )
        this.tracking?.track('Organization Page Updated', {
          organization_slug: this.organization.organizationSlug,
          update_type: ['Retrait Logo']
        })
        this.pushNotification({
          type: 'success',
          title: this.$t('toast.logoDelete.title'),
          body: this.$t('toast.logoDelete.body'),
          timeout: 5000
        })
      } catch (error) {
        this.pushNotification({
          type: 'danger',
          title: this.$t(
            `error.code.${get(error, 'response.status', 500)}.title`
          ),
          body: this.$t(
            `error.code.${get(error, 'response.status', 500)}.message`
          )
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import './styles/logo';
</style>
