<template>
  <div id="map" />
</template>

<script>
import {
  Map,
  NavigationControl,
  Marker
} from 'maplibre-gl'

export default {
  name: 'MapPartials',
  props: {
    coordinates: {
      type: Array,
      required: true
    }
  },
  mounted () {
    if (this.coordinates) {
      const map = new Map({
        container: 'map', // container id
        style: 'https://maps.helloasso.com/styles/bright/style.json?optimize=true', // styles url
        center: [this.coordinates[1], this.coordinates[0]], // [lng, lat]
        zoom: 16
      })

      new Marker({ color: '#F45554' })
        .setLngLat([this.coordinates[1], this.coordinates[0]])
        .addTo(map)

      const nav = new NavigationControl()
      map.addControl(nav, 'top-left')

      map.boxZoom.disable()
      map.scrollZoom.disable()
      map.doubleClickZoom.disable()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/map';
</style>
