<template>
  <!-- public mode -->
  <a
    v-if="!editMode && organization[type]"
    :href="formattedUrl(organization[type])"
    target="_blank"
    rel="noreferrer noopener"
    :class="`social-item social-item--${type.toLowerCase()}`"
    :data-ux="`Explore_OrganizationPublicPage_Contact_social${capitalize(type)}`"
    :title="`${$t(`organization.contact.socials.socialNetwork.${type}.labelIcon`)}: ${organization[type]}`"
  >
    <HaIcon
      class="social-item-icon"
      :icon="icon"
    />
    <span class="social-item-label">
      {{ organization[type] }}
    </span>
  </a>
  <!-- edit mode -->
  <div
    v-if="editMode"
    class="social-item social-item--edit"
  >
    <label
      :for="type.toLowerCase()"
      :class="`social-label social-label--${capitalize(type)}`"
      :title="
        $t(
          `organization.contact.socials.socialNetwork.${type}.labelEdit`
        )
      "
    >
      <HaIcon
        :icon="icon"
        class="social-item-icon"
      />
      <HaValidatorInput
        v-slot="{ validated, valid, errors }"
        v-model="value"
        name="socialNetworkUrl"
        rules="url"
        slim
      >
        <HaInputGroup>
          <HaInput
            :id="type.toLowerCase()"
            v-model="value"
            name="socialNetworkUrl"
            :is-valid="validated ? valid : null"
            :placeholder="
              $t(
                `organization.contact.socials.socialNetwork.${type}.placeholder`
              )
            "
          />
          <ha-button
            :data-ux="`Explore_OrganizationPublicPage_EditMode_Contact_Save${capitalize(
              type
            )}`"
            :loading="isLoading"
            :disabled="isLoading"
            :title="$t('button.save')"
            @click="save()"
          >
            <template #icon>
              <HaIcon :icon="faFloppyDisk" />
            </template>
          </ha-button>
        </HaInputGroup>
        <span
          v-if="errors.length"
          class="social--error"
        >
          {{ errors[0] }}
        </span>
      </HaValidatorInput>
    </label>
    <ha-dropdown
      v-if="editMode && hasValue && !toUpdate"
      alignment="right"
    >
      <template #trigger>
        <ha-button
          class="social--edit"
          variant="flat"
          color="basic"
          :data-ux="`Explore_OrganizationPublicPage_EditMode_Contact_Toggle_Dropdown${capitalize(
            type
          )}`"
          :title="$t('organization.contact.openSettings')"
        >
          <HaIcon :icon="faEllipsisVertical" />
        </ha-button>
      </template>
      <template #content>
        <ha-menu-list>
          <ha-menu-list-item
            :icon="faPen"
            :label="$t('button.update')"
            :data-ux="`Explore_OrganizationPublicPage_EditMode_Contact_Update${capitalize(
              type
            )}`"
            @click="update()"
          />
          <ha-menu-list-item
            :icon="faTrash"
            :label="$t('button.delete')"
            :data-ux="`Explore_OrganizationPublicPage_EditMode_Contact_Delete${capitalize(
              type
            )}`"
            highlighted
            @click="remove()"
          />
        </ha-menu-list>
      </template>
    </ha-dropdown>
  </div>
</template>

<script setup lang="ts">
import {
  faFloppyDisk,
  faEllipsisVertical,
  faPen,
  faTrash,
  faDesktop
} from '@fortawesome/pro-solid-svg-icons'
import {
  faFacebookF,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons'
import { capitalizeFirstLetter } from '@/helpers'
import useEditMode from '@/composables/useEditMode'

import {
  HaButton,
  HaDropdown,
  HaInput,
  HaInputGroup,
  HaMenuList,
  HaMenuListItem,
  HaIcon,
  useNotifications,
  HaValidatorInput
} from '@ha/components-v3'
import useSegment from '@/composables/useSegment'
import { computed, ref, useI18n, watch } from '#imports'
import { useOrganizationStore } from '~/store/organization.store'
const props = defineProps<{ type: string }>()
const { pushNotification } = useNotifications()
const { trackUpdateOrganization } = useSegment()
const { editMode } = useEditMode()
const i18n = useI18n()

const value = ref(null)
const toUpdate = ref(false)
const isLoading = ref(false)
const organizationStore = useOrganizationStore()

const organization = computed(() => organizationStore.organization)

const hasValue = computed(() => {
  return organization.value[props.type]
})

const icon = computed(() => {
  const icons = {
    webSite: faDesktop,
    facebookPage: faFacebookF,
    twitterPage: faXTwitter
  }

  return icons[props.type]
})

watch(
  organization,
  () => {
    value.value ??= organization.value[props.type]
  },
  {
    deep: true,
    immediate: true
  }
)

const update = () => {
  toUpdate.value = true
}

const save = async () => {
  isLoading.value = true

  const payload = {
    address: organization.value.address,
    category: organization.value.category,
    city: organization.value.city,
    description: organization.value.description,
    displayCoordinates: organization.value.displayCoordinates,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    email: organization.value.email,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    facebookPage: organization.value.facebookPage,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    geoLocation: organization.value.geoLocation,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    instagramPage: organization.value.instagramPage,
    longDescription: organization.value.longDescription,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    phone: organization.value.phone,
    twitterPage: organization.value.twitterPage,
    webSite: organization.value.webSite,
    youtubePage: organization.value.youtubePage,
    zipCode: organization.value.zipCode
  }

  payload[props.type] = value.value
  trackUpdateOrganization(
    organization.value.organizationSlug,
    organization.value,
    payload
  )

  try {
    await organizationStore.saveOrganizationData({
      slug: organization.value.organizationSlug,
      payload
    })

    await organizationStore.saveOrganizationData({
      slug: organization.value.organizationSlug,
      payload
    })

    toUpdate.value = false

    pushNotification({
      type: 'success',
      title: i18n.t('toast.infoUpdate.title'),
      body: i18n.t('toast.infoUpdate.body'),
      timeout: 5000
    })
  } catch (error) {
    pushNotification({
      type: 'danger',
      title: i18n.t(`error.code.${error.response.status}.title`),
      body: i18n.t(`error.code.${error.response.status}.message`),
      timeout: 5000
    })
  } finally {
    isLoading.value = false
  }
}

const remove = () => {
  value.value = ''
  const payload = {
    address: organization.value.address,
    category: organization.value.category,
    city: organization.value.city,
    description: organization.value.description,
    displayCoordinates: organization.value.displayCoordinates,
   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    email: organization.value.email,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    facebookPage: organization.value.facebookPage,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    geoLocation: organization.value.geoLocation,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    instagramPage: organization.value.instagramPage,
    longDescription: organization.value.longDescription,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    phone: organization.value.phone,
    twitterPage: organization.value.twitterPage,
    webSite: organization.value.webSite,
    youtubePage: organization.value.youtubePage,
    zipCode: organization.value.zipCode
  }
  payload[props.type] = value.value

  try {
    organizationStore.saveOrganizationData({
      slug: organization.value.organizationSlug,
      payload
    })

    pushNotification({
      type: 'success',
      title: i18n.t('toast.infoDelete.title'),
      body: i18n.t('toast.infoDelete.body'),
      timeout: 5000
    })
  } catch (error) {
    pushNotification({
      type: 'danger',
      title: i18n.t(`error.code.${error.response.status}.title`),
      body: i18n.t(`error.code.${error.response.status}.message`),
      timeout: 5000
    })
  }
}

const formattedUrl = (url) => {
  const pattern = /^((http|https|ftp):\/\/)/
  if (!pattern.test(url)) {
    return `https://${url}`
  }
  return url
}

const capitalize = (value) => {
  return capitalizeFirstLetter(value)
}
</script>

<style lang="scss" scoped>
.social-item {
  display: flex;
  align-items: center;
  padding: var(--hads-spacing-200) 0;

  &--edit {
    display: flex;
    align-items: center;
    gap: var(--hads-spacing-200);
  }
}

.social-label {
  display: flex;
  align-items: center;
  gap: var(--hads-spacing-200);
}

.social-item-icon {
  width: var(--hads-spacing-300);
  margin-right: var(--hads-spacing-200);
}

// hover
.social-item:hover {
  color: var(--hads-color-iris);
}

.social-item {
  &--website {
    color: var(--ha-color-text-light);
  }

  &--facebookpage {
    color: var(--hads-color-facebook);
  }

  &--twitterpage {
    color: var(--hads-color-twitter);
  }

  &--instagrampage {
    color: var(--hads-color-instagram);
  }

  &--linkedinpage {
    color: var(--hads-color-linkedin);
  }

  &--youtubepage {
    color: var(--hads-color-youtube);
  }
}

.social-item-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &--edit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $ha-unit * 5; // 40px
    height: $ha-unit * 5; // 40px
    padding: 0;
    color: var(--ha-color-text-light);
    border: $ha-border-width-regular solid transparent;
    border-radius: $ha-radius-regular;
    cursor: pointer;

    @include transition-base(
      (color, background-color, border-color, box-shadow)
    );

    &:hover {
      color: var(--ha-color-text);
      background-color: set-alpha(text, 0.05);
      cursor: pointer;
    }

    &:focus {
      border-color: set-lightness(border, 1);
      outline: none;
      box-shadow: inset 0 0 0 ($ha-unit * 0.125)
        set-lightness(border, 1); // 0 0 0 1px
    }

    &:active {
      background-color: set-alpha(text, 0.1);
    }
  }

  &--error {
    display: block;
    margin-top: $ha-spacing-mini;
    color: var(--ha-color-danger);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-tiny;
    line-height: $ha-line-height-small;
  }

  .HaDropdown {
    flex: 0 0 $ha-unit * 5; // 0 0 40px
  }

  .HaInputGroup {
    .HaButton {
      width: $ha-unit * 5.25; // 42px
      min-width: $ha-unit * 5.25; // 42px
    }
  }
}

.social {
  input {
    text-overflow: ellipsis;
  }
}

:deep(.HaInputGroup > *) {
  flex: initial;
}

:deep(*) {
  .HaDropdown--Content {
    width: 80%;
  }
}
</style>
