<script setup lang="ts">
import { ref, onMounted } from '#imports'

interface Props {
  src: string
  params?: { [key: string]: number }
}

const props = defineProps<Props>()

const videos = [
  {
    reg: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/i,
    url: 'https://www.youtube.com/embed/$5',
    params: {
      'picture-in-picture': 1,
      accelerometer: 1,
      gyroscope: 1
    }
  },
  {
    reg: /^.*vimeo.com\/(\d+)($|\/|\b)/i,
    url: 'https://player.vimeo.com/video/$1',
    params: {
      title: 0,
      byline: 0,
      portrait: 0
    }
  },

  {
    reg: /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#&?]*).*/i,
    url: 'https://www.dailymotion.com/embed/video/$1',
    params: {
      autoplay: 0
    }
  }
]

const valid = ref(false)
const url = ref('')

const toQueryParams = (params: Props['params'] = {}) =>
  Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')

onMounted(() => {
  videos.forEach((video) => {
    const matching = video.reg.exec(props.src)

    if (matching) {
      const allParams: Props['params'] = Object.assign(
        {},
        video.params,
        props.params
      )

      const query = toQueryParams(allParams)
      const and = video.url.includes('?') ? '&' : '?'
      url.value = props.src.replace(video.reg, video.url) + and + query

      valid.value = true
    }
  })
})
</script>

<script lang="ts">
export default {
  name: 'VideoEmbed'
}
</script>

<template>
  <div v-if="valid">
    <iframe
      loading="lazy"
      sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
      allowfullscreen
      :src="url"
    />
  </div>
</template>
