<template>
  <div class="Editor">
    <ClientOnly :placeholder="$t('ckEditor.loading')">
      <CKEditor
        ref="editorRef"
        v-model="data"
        :editor="FullFreeBuildEditor"
        :config="editorConfig"
      />
    </ClientOnly>
    <ha-button
      data-ux="Explore_OrganizationPublicPage_EditMode_About_SaveLongDescription"
      @click="onSave(data)"
    >
      {{ $t('button.save') }}
    </ha-button>
  </div>
</template>

<script setup>
import { ref, computed, watch, useI18n } from '#imports'
import { HaButton } from '@ha/components-v3'

// We need to import this package client side with a fallback bc error on orga page (no in edition mode) if not
let FullFreeBuildEditor;
let CKEditor;

if (import.meta.client) {
  FullFreeBuildEditor = (await import('@blowstack/ckeditor5-full-free-build')).default;
  CKEditor = (await import('@ckeditor/ckeditor5-vue')).component;
  await import('@blowstack/ckeditor5-full-free-build/build/translations/fr')
} else {
  CKEditor = { component: { template: '<div></div>' } };
}
const i18n = useI18n()
const editorRef = ref()


const props = defineProps({
  initialValue: {
    type: String,
    required: false,
    default: ''
  },
  uploadUrl: {
    type: String,
    required: true
  },
  uploadHeaders: {
    type: Object,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: 'Ajouter un texte...'
  },
  onSave: {
    type: Function,
    default: () => {}
  }
})
const updateHeaders = computed(() => props.uploadHeaders)
const data = ref(props.initialValue)

const editorConfig = computed(() => {
  return {
    language: 'fr',
    defaultLanguage: 'fr',
    removePlugins: ['Autosave', 'ImageResize', 'Title'],
    link: {
      defaultProtocol: 'https://',
      decorators: {
        addTargetToExternalLinks: true
      }
    },
    alignment: {
      language: 'fr'
    },
    toolbar: {
      language: 'fr',
      items: [
        'heading',
        'bold',
        'italic',
        'underline',
        'bulletedList',
        'numberedList',
        'link',
        'mediaEmbed',
        'imageUpload',
        'alignment:left',
        'alignment:center',
        'alignment:right',
        'alignment:justify',
      ]
    },
    heading: {
      language: 'fr',
      options: [
        {
          model: 'paragraph',
          title: i18n.t('editor.paragraph'),
          class: 'ck-heading_paragraph'
        },
        {
          model: 'heading1',
          view: 'h3',
          title: i18n.t('editor.heading1'),
          class: 'ck-heading_heading1'
        },
        {
          model: 'heading2',
          view: 'h4',
          title: i18n.t('editor.heading2'),
          class: 'ck-heading_heading2'
        }
      ]
    },
    simpleUpload: {
      // The URL that the images are uploaded to.
      uploadUrl: props.uploadUrl,

      // Headers sent along with the XMLHttpRequest to the upload server.
      headers: props.uploadHeaders
    },
    image: {
      toolbar: [
        'imageTextAlternative',
        '|',
        'imageStyle:alignLeft',
        'imageStyle:full',
        'imageStyle:alignRight'
      ],
      styles: ['full', 'alignLeft', 'alignRight']
    },
    mediaEmbed: {
      previewsInData: true,
      removeProviders: ['instagram', 'twitter', 'facebook']
    },
    placeholder: props.placeholder
  }
})

  watch(updateHeaders, (newValue) => {
    // update the headers (mostly the authentication token) used for image uploading
    editorRef.value.instance.config.set('simpleUpload.headers', newValue)
  })
</script>

<style lang="scss">
@import './style';
</style>
