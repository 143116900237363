<template>
  <li class="MediaList--Item">
    <figure class="Media">
      <div class="Media--Preview">
        <img
          v-if="!isVideo"
          :src="
            resizedSrc(media.url, {
              width: 340,
              height: 170
            })
          "
          alt=""
          loading="lazy"
        />
        <video-embed
          class="Media--PreviewVideo"
          :src="media.url"
        />
      </div>
      <figcaption class="Media--Legend">
        <label
          :for="`mediaLegend-${index}`"
          class="visually-hidden"
        >
          {{ $t('organization.carousel.legendLabel') }}
        </label>
        {{ media.caption }}
      </figcaption>
      <ha-button
        class="Media--Delete"
        color="danger"
        data-ux="Explore_OrganizationPublicPage_EditMode_Carousel_DeleteMedia"
        @click="deleteMedia(media.id, isVideo)"
      >
        <span class="visually-hidden">
          {{ $t('button.delete') }}
        </span>
      </ha-button>
    </figure>
  </li>
</template>

<script>
import { get } from 'lodash-es'
import { resizedSrc } from '@/helpers/utils'
import { useNotifications, HaButton } from '@ha/components-v3'
import { useI18n } from '#imports'

import VideoEmbed from '@/components/VideoEmbed/VideoEmbed.vue'
import { useOrganizationStore } from '@/store/organization.store'

export default {
  name: 'Media',
  components: {
    HaButton,
    VideoEmbed
  },
  props: {
    media: {
      type: Object
    },
    index: {
      type: Number,
      default: 1
    },
    isVideo: Boolean
  },
  setup() {
    const { pushNotification } = useNotifications()
    const i18n = useI18n()
    const organizationStore = useOrganizationStore()

    return { pushNotification, i18n, resizedSrc, organizationStore }
  },
  data() {
    return {
      mediaLegend: null
    }
  },
  computed: {
    organization() {
      return this.organizationStore.organization
    }
  },
  methods: {
    deleteMedia(id, isVideo) {
      this.organizationStore[isVideo ? 'deleteVideo' : 'deleteImage'](
        {
          slug: this.organization.organizationSlug,
          id
        }
      )
        .then(() => {
          this.pushNotification({
            type: 'success',
            title: this.$t(
              `organization.carousel.delete.${
                isVideo ? 'video' : 'image'
              }.title`
            ),
            body: this.$t(
              `organization.carousel.delete.${isVideo ? 'video' : 'image'}.body`
            ),
            timeout: 5000
          })
        })
        .catch((err) => {
          const status = get(err, 'response.status')
          this.errorUpload = this.$t(`error.code.${status}.message`)

          this.pushNotification({
            type: 'danger',
            title: this.$t('error.upload'),
            body: this.$t(`error.code.${status}.message`),
            timeout: 5000
          })

          this.file = null
        })
        .finally(() => {
          this.isUploading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import './styles/media';
</style>
