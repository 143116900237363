<template>
  <div
    v-if="organization.displayCoordinates"
    :class="`Info Info-${capitalize(type)}`"
  >
    <div class="Info--Wrapper">
      <label
        :for="`${type.toLowerCase()}`"
        class="Info--Label"
      >
        {{
          $t(`organization.contact.infos.${type.toLowerCase()}.label`)
        }}
      </label>
      <div
        class="Info--Field"
        data-test-id="AddressInfoField"
      >
        <!-- Content (classic view) -->
        <slot
          v-if="(!editMode || hasValue) && !toUpdate"
          name="content"
        />
        <!-- Content (edition view) -->
        <template v-else>
          <!-- Edit mode: ON -->
          <template v-if="!hasValue || toUpdate">
            <!-- Input + Save (default) -->
            <template v-if="!isManualAddress">
              <HaValidatorInput
                v-slot="{ validated, valid, errors }"
                v-model="value"
                name="addressAuto"
                :rules="validationRules"
                slim
              >
                <HaInputGroup>
                  <form
                    v-if="type === 'address'"
                    @submit.prevent
                  >
                    <ha-auto-complete
                      id="address"
                      name="address"
                      :model-value="address"
                      autocomplete="off"
                      no-filter
                      :options="addresses"
                      :default-value="initialValue"
                      :is-valid="validated ? valid : null"
                      :is-loading="addressesLoading"
                      data-ux="Explore_OrganizationPublicPage_Contact_AddressAutocomplete"
                      @input="searchAddresses"
                      @update:model-value="selectAddress"
                    />
                  </form>
                  <HaInput
                    v-else
                    :id="type"
                    v-model="value"
                    :is-valid="validated ? valid : null"
                    :name="type"
                    :placeholder="
                      $t(
                        `organization.contact.infos.${type.toLowerCase()}.placeholder`
                      )
                    "
                    :data-ux="`Explore_OrganizationPublicPage_Contact_${capitalize(
                      type
                    )}`"
                  />
                  <!-- Save -->
                  <ha-button
                    :data-ux="`Explore_OrganizationPublicPage_Contact_Save${capitalize(
                      type
                    )}`"
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="save()"
                  >
                    <template #icon>
                      <HaIcon :icon="faFloppyDisk" />
                      <span class="visually-hidden">
                        {{ $t('button.save') }}
                      </span>
                    </template>
                  </ha-button>
                </HaInputGroup>
                <!-- Errors -->
                <span
                  v-if="errors.length"
                  class="Info--Error"
                >
                  {{ errors[0] }}
                </span>
              </HaValidatorInput>
            </template>
            <!-- Input (manual address) -->
            <template v-if="isManualAddress && type === 'address'">
              <HaValidatorForm v-slot="{ invalid }">
                <HaValidatorInput
                  v-slot="{ validated, valid, errors }"
                  ref="address"
                  v-model="address"
                  name="address"
                  rules="required"
                  slim
                >
                  <HaFormField :is-valid="validated ? valid : null">
                    <template #label>
                      {{
                        $t(
                          `organization.contact.infos.address.street`
                        )
                      }}
                    </template>
                    <HaInput
                      id="manualAddress"
                      v-model="address"
                      name="address"
                      :is-valid="validated ? valid : null"
                      :placeholder="
                        $t(
                          'organization.contact.infos.address.placeholderAddress'
                        )
                      "
                      data-ux="Explore_OrganizationPublicPage_Contact_AddressManual_Street"
                    />
                    <template #error>
                      {{ errors[0] }}
                    </template>
                  </HaFormField>
                </HaValidatorInput>
                <div class="Info--FieldLine">
                  <HaValidatorInput
                    v-slot="{ validated, valid, errors }"
                    ref="zipCode"
                    v-model="zipCode"
                    name="zipCode"
                    rules="required|integer"
                    slim
                  >
                    <ha-form-field
                      :is-valid="validated ? valid : null"
                    >
                      <template #label>
                        {{
                          $t(
                            `organization.contact.infos.address.zipCode`
                          )
                        }}
                      </template>
                      <HaInput
                        v-model="zipCode"
                        name="zipCode"
                        :is-valid="validated ? valid : null"
                        :placeholder="
                          $t(
                            'organization.contact.infos.address.placeholderZipcode'
                          )
                        "
                        data-ux="Explore_OrganizationPublicPage_Contact_AddressManual_Zipcode"
                      />
                      <template #error>
                        {{ errors[0] }}
                      </template>
                    </ha-form-field>
                  </HaValidatorInput>
                  <HaValidatorInput
                    v-slot="{ validated, valid, errors }"
                    ref="city"
                    v-model="city"
                    name="city"
                    rules="required"
                    slim
                  >
                    <ha-form-field
                      :is-valid="validated ? valid : null"
                    >
                      <template #label>
                        {{
                          $t(
                            `organization.contact.infos.address.city`
                          )
                        }}
                      </template>
                      <HaInput
                        v-model="city"
                        name="city"
                        :is-valid="validated ? valid : null"
                        :placeholder="
                          $t(
                            'organization.contact.infos.address.placeholderCity'
                          )
                        "
                        data-ux="Explore_OrganizationPublicPage_Contact_AddressManual_City"
                      />
                      <template #error>
                        {{ errors[0] }}
                      </template>
                    </ha-form-field>
                  </HaValidatorInput>
                </div>
                <div class="Info--FieldNotice">
                  {{
                    $t(
                      `organization.contact.infos.coordinates.customCoordinates`
                    )
                  }}
                </div>
                <div class="Info--FieldLine">
                  <HaValidatorInput
                    v-slot="{ validated, valid, errors }"
                    ref="latitude"
                    v-model="geoLocation.latitude"
                    name="latitude"
                    rules=""
                    slim
                  >
                    <ha-form-field
                      :is-valid="validated ? valid : null"
                    >
                      <template #label>
                        {{
                          $t(
                            `organization.contact.infos.coordinates.latitude.label`
                          )
                        }}
                      </template>
                      <HaInput
                        v-model="geoLocation.latitude"
                        name="latitude"
                        :placeholder="
                          $t(
                            'organization.contact.infos.coordinates.latitude.placeholder'
                          )
                        "
                        :is-valid="validated ? valid : null"
                        data-ux="Explore_OrganizationPublicPage_Contact_AddressManual_Latitude"
                      />
                      <template #error>
                        {{ errors[0] }}
                      </template>
                    </ha-form-field>
                  </HaValidatorInput>
                  <HaValidatorInput
                    v-slot="{ validated, valid, errors }"
                    ref="longitude"
                    v-model="geoLocation.longitude"
                    name="longitude"
                    rules=""
                    slim
                  >
                    <ha-form-field
                      :is-valid="validated ? valid : null"
                    >
                      <template #label>
                        {{
                          $t(
                            `organization.contact.infos.coordinates.longitude.label`
                          )
                        }}
                      </template>
                      <HaInput
                        v-model="geoLocation.longitude"
                        name="longitude"
                        :placeholder="
                          $t(
                            'organization.contact.infos.coordinates.longitude.placeholder'
                          )
                        "
                        :is-valid="validated ? valid : null"
                        data-ux="Explore_OrganizationPublicPage_Contact_AddressManual_Longitude"
                      />
                      <template #error>
                        {{ errors[0] }}
                      </template>
                    </ha-form-field>
                  </HaValidatorInput>
                </div>
                <!-- Save -->
                <ha-button
                  :data-ux="`Explore_OrganizationPublicPage_Contact_Save${capitalize(
                    type
                  )}`"
                  fluid
                  :loading="isLoading"
                  :disabled="isLoading || invalid"
                  @click="save()"
                >
                  <template #icon>
                    <HaIcon :icon="faFloppyDisk" />
                  </template>
                  {{ $t('button.save') }}
                </ha-button>
              </HaValidatorForm>
            </template>
            <!-- Switch autocomplete/manual address -->
            <ha-button
              v-if="type === 'address'"
              variant="link"
              size="small"
              :fluid="isManualAddress"
              data-ux="Explore_OrganizationPublicPage_Contact_SwitchAddressType"
              @click="toggleManualDisplay()"
            >
              {{
                isManualAddress
                  ? $t(
                      `organization.contact.infos.${type.toLowerCase()}.switchToAuto`
                    )
                  : $t(
                      `organization.contact.infos.${type.toLowerCase()}.switchToManual`
                    )
              }}
            </ha-button>
          </template>
        </template>
      </div>
    </div>
    <!-- Dropdown -->
    <ha-dropdown
      v-if="editMode && hasValue && !toUpdate"
      alignment="right"
    >
      <template #trigger>
        <ha-button
          class="Info--Edit"
          variant="flat"
          color="basic"
          :data-ux="`Explore_OrganizationPublicPage_Contact_Toggle_Dropdown${capitalize(
            type
          )}`"
        >
          <HaIcon :icon="faEllipsisVertical" />
          <span class="visually-hidden">
            {{ $t('organization.contact.openSettings') }}
          </span>
        </ha-button>
      </template>
      <template #content>
        <ha-menu-list>
          <ha-menu-list-item
            :icon="faPen"
            :label="$t('button.update')"
            :data-ux="`Explore_OrganizationPublicPage_Contact_Update${capitalize(type)}`"
            @click="update()"
          />
          <ha-menu-list-item
            v-if="isDeletable"
            :icon="faTrash"
            :label="$t('button.delete')"
            :data-ux="`Explore_OrganizationPublicPage_Contact_Delete${capitalize(type)}`"
            highlighted
            @click="remove()"
          />
        </ha-menu-list>
      </template>
    </ha-dropdown>
  </div>
</template>

<script>
import {
  faFloppyDisk,
  faEllipsisVertical,
  faPen,
  faTrash
} from '@fortawesome/pro-solid-svg-icons'
import {
  HaAutoComplete,
  HaButton,
  HaDropdown,
  HaInput,
  HaInputGroup,
  HaMenuList,
  HaMenuListItem,
  HaFormField,
  HaIcon,
  HaValidatorForm,
  HaValidatorInput,
  useNotifications
} from '@ha/components-v3'
import { functions } from '@ha/helpers'
import { capitalizeFirstLetter } from '@/helpers'
import useSegment from '@/composables/useSegment'
import useEditMode from '@/composables/useEditMode'
import { useI18n } from '#imports'
import { useAddressStore } from '@/store/address.store'
import { useOrganizationStore } from '@/store/organization.store'

export default {
  name: 'Info',
  components: {
    HaAutoComplete,
    HaButton,
    HaDropdown,
    HaFormField,
    HaInput,
    HaInputGroup,
    HaMenuList,
    HaMenuListItem,
    HaValidatorForm,
    HaValidatorInput,
    HaIcon
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    isDeletable: Boolean
  },
  setup() {
    const { pushNotification } = useNotifications()
    const { trackUpdateOrganization } = useSegment()
    const { editMode } = useEditMode()
    const i18n = useI18n()
    const addressStore = useAddressStore()
    const organizationStore = useOrganizationStore()

    return {
      pushNotification,
      trackUpdateOrganization,
      editMode,
      i18n,
      addressStore,
      organizationStore
    }
  },
  data() {
    return {
      value: null,
      toUpdate: false,
      searchQuery: this.organizationStore?.organization?.address || '',
      searchAddresses: functions.debounce(this.getAddress),
      addressesLoading: false,
      addresses: [],
      address: this.organizationStore.organization.address,
      zipCode: this.organizationStore.organization.zipCode,
      city: this.organizationStore.organization.city,
      geoLocation: {
        latitude:
          this.organizationStore?.organization?.geolocation
            ?.latitude,
        longitude:
          this.organizationStore?.organization?.geolocation
            ?.longitude
      },
      hasCustomGeolocation: false,
      isManualAddress: false,
      isLoading: false,
      faFloppyDisk,
      faEllipsisVertical,
      faPen,
      faTrash
    }
  },
  computed: {
    organization() {
      return this.organizationStore.organization
    },
    hasValue() {
      switch (this.type) {
        case 'address':
          return this.organization?.address
        case 'email':
          return this.organization?.contact?.email
        case 'phone':
          return this.organization?.contact?.phoneNumber
        default:
          return false
      }
    },
    initialValue() {
      if (this.hasValue) {
        return this.organization.address !== this.organization.city
          ? `${this.organization.address} ${this.organization.zipCode} ${this.organization.city}`
          : `${this.organization.city} ${this.organization.zipCode}`
      }
      return null
    },
    validationRules() {
      switch (this.type) {
        case 'email':
          return 'email'
        case 'phone':
          return 'phone'
        case 'address':
          return 'required'
        default:
          return ''
      }
    }
  },
  watch: {
    organization() {
      if (!this.value) {
        this.value = this.hasValue
      }
    },
    geoLocation: {
      deep: true,
      handler() {
        this.hasCustomGeolocation = true
      }
    }
  },
  methods: {
    update() {
      this.toUpdate = true
    },
    async save() {
      this.isLoading = true

      const payload = {
        address: this.organization.address,
        category: this.organization.category,
        city: this.organization.city,
        description: this.organization.description,
        displayCoordinates: this.organization.displayCoordinates,
        email: this.organization.email,
        facebookPage: this.organization.facebookPage,
        geoLocation: this.organization.geoLocation,
        instagramPage: this.organization.instagramPage,
        longDescription: this.organization.longDescription,
        phone: this.organization.phone,
        twitterPage: this.organization.twitterPage,
        webSite: this.organization.webSite,
        youtubePage: this.organization.youtubePage,
        zipCode: this.organization.zipCode
      }

      if (this.type === 'address') {
        payload.address = this.address
        payload.city = this.city
        payload.zipCode = this.zipCode
        payload.geoLocation = this.addressStore.geoLocation

        if (this.isManualAddress) {
          const manualAddress = await this.addressStore.fetchManualAddress(
            {
              address: this.address,
              zipCode: this.zipCode,
              city: this.city
            }
          )
          // we want to normalize zipcode, city and geoloc
          // but not the address for Stripe verification purpose
          payload.zipCode = manualAddress?.zipCode
          payload.city = manualAddress?.city

          // if the user wants a custom geolocation in case of a bad one given by datagouv
          const newLatitude =
            this.hasCustomGeolocation &&
            this.geoLocation.latitude !==
              manualAddress?.coordinates[0]
              ? this.geoLocation.latitude
              : manualAddress?.coordinates[0]
          const newLongitude =
            this.hasCustomGeolocation &&
            this.geoLocation.longitude !==
              manualAddress?.coordinates[1]
              ? this.geoLocation.longitude
              : manualAddress?.coordinates[1]

          payload.geoLocation = {
            latitude: newLatitude,
            longitude: newLongitude
          }
        }
      } else {
        payload[this.type] = this.value
      }
      this.trackUpdateOrganization(
        this.organization.organizationSlug,
        this.organization,
        payload
      )

      return this.organizationStore
        .saveOrganizationData({
          slug: this.organization.organizationSlug,
          payload
        })
        .then(() => {
          this.toUpdate = false
          this.hasCustomGeolocation = false
          this.pushNotification({
            type: 'success',
            title: this.$t('toast.infoUpdate.title'),
            body: this.$t(
              this.isManualAddress
                ? 'toast.infoUpdate.bodyAddressManual'
                : 'toast.infoUpdate.body'
            ),
            timeout: 5000
          })
        })
        .catch((error) => {
          if (
            this.type === 'address' &&
            error.response.status === 400
          ) {
            this.pushNotification({
              type: 'danger',
              title: this.$t(
                'organization.contact.infos.error.unknownAddress.title'
              ),
              body: this.$t(
                'organization.contact.infos.error.unknownAddress.body'
              ),
              timeout: 5000
            })
          } else {
            this.pushNotification({
              type: 'danger',
              title: this.$t(
                `error.code.${error.response.status}.title`
              ),
              body: this.$t(
                `error.code.${error.response.status}.message`
              ),
              timeout: 5000
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    remove() {
      this.value = ''
      const payload = {
        address: this.organization.address,
        category: this.organization.category,
        city: this.organization.city,
        description: this.organization.description,
        displayCoordinates: this.organization.displayCoordinates,
        email: this.organization.email,
        facebookPage: this.organization.facebookPage,
        geoLocation: this.organization.geoLocation,
        instagramPage: this.organization.instagramPage,
        longDescription: this.organization.longDescription,
        phone: this.organization.phone,
        twitterPage: this.organization.twitterPage,
        webSite: this.organization.webSite,
        youtubePage: this.organization.youtubePage,
        zipCode: this.organization.zipCode
      }

      payload[this.type] = this.value
      this.trackUpdateOrganization(
        this.organization.organizationSlug,
        this.organization,
        payload
      )
      return this.organizationStore
        .saveOrganizationData({
          slug: this.organization.organizationSlug,
          payload
        })
        .then(() => {
          this.pushNotification({
            type: 'success',
            title: this.$t('toast.infoDelete.title'),
            body: this.$t('toast.infoDelete.body'),
            timeout: 5000
          })
        })
        .catch((error) => {
          this.pushNotification({
            type: 'danger',
            title: this.$t(
              `error.code.${error.response.status}.title`
            ),
            body: this.$t(
              `error.code.${error.response.status}.message`
            ),
            timeout: 5000
          })
        })
    },
    selectAddress({ value }) {
      if (!value) {
        return
      }

      this.address = value.address
      this.zipCode = value.zipCode
      this.city = value.city
      this.country = value.country

      this.addressStore.setGeolocation(value.geoLocation)
    },
    getAddress(queryString) {
      this.addressesLoading = true
      this.addressStore.fetchAddresses(queryString)
        .then((addresses) => {
          if (Array.isArray(addresses)) {
            this.addresses = addresses.map((address) => {
              return {
                text: address?.properties.label,
                value: {
                  address: address?.properties.name,
                  city: address?.properties.city,
                  geoLocation: {
                    latitude: address?.properties.geometry[1],
                    longitude: address?.properties.geometry[0]
                  },
                  zipCode: address?.properties.zipcode
                }
              }
            })
          }
        })
        .finally(() => {
          this.addressesLoading = false
        })
    },
    capitalize(value) {
      return capitalizeFirstLetter(value)
    },
    toggleManualDisplay() {
      this.isManualAddress = !this.isManualAddress
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/info';
</style>
