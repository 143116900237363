<template>
  <div
    id="highlight"
    :class="['Highlight', { 'Highlight-Only': formsNumber === 1 }]"
  >
    <div class="HighlightWrapper">
      <div class="Container">
        <span class="Subtitle">
          <em>{{ $t('organization.highlight.subtitle') }}</em>
        </span>
        <ul
          class="ActionHighlight"
          data-test-id="ActionHighlightCard"
        >
          <Action
            :form="formToHighlight"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { filter, orderBy, size } from 'lodash-es'
import Action from '@/components/Organization/Partials/Action.vue'
import { computed } from '#imports'
import { useFormsStore } from '@/store/forms.store';

const formsStore = useFormsStore()


const isEnded = (form) => {
  const endDate = form?.endDate
  const startDate = form?.startDate

  if (endDate) {
    return new Date(endDate).getTime() < Date.now()
  }
  if (startDate) {
    return new Date(startDate).getTime() < Date.now()
  }
  return false
}

const forms = computed(() => {
  const formsData = orderBy(
    formsStore.forms,
    ['meta.updatedAt'],
    ['desc']
  )

  return filter(formsData, (form) => {
    return !isEnded(form)
  })
})
const formToHighlight = computed(() => forms.value.slice(0, 1)[0])
const formsNumber = computed(() => {
  return size(forms.value)
})

</script>

<style lang="scss">
@import './styles/highlight';
</style>
