<template>
  <section
    id="report"
    class="Organization--Report"
  >
    <ha-button
      variant="link"
      color="danger"
      data-testid="Explore_OrganizationPublicPage_Report_Toggle_ModalReport"
      @click="displayModalReport = true"
    >
      <template #icon>
        <HaIcon :icon="faTriangleExclamation" />
      </template>
      {{ $t('organization.report.reportPage') }}
    </ha-button>
    <ModalReport
      v-if="displayModalReport"
      @close="displayModalReport = false"
    />
  </section>
</template>

<script setup lang="ts">
import { ref, useI18n } from '#imports'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { HaButton, HaIcon } from '@ha/components-v3'
import ModalReport from '@/components/Modal/ModalReport.vue'

const displayModalReport = ref(false)

const i18n = useI18n()
</script>

<style lang="scss">
.Organization {
  .Organization--Report {
    > .HaButton {
      display: flex;
      margin: 0 auto;
    }
  }

  .ModalReport {
    h2 {
      margin-bottom: 0;
      text-align: left;
    }
  }
}
</style>
