<template>
  <li class="MediaList--Item">
    <HaValidatorForm v-slot="{ valid }">
      <div
        v-if="showImageUploader"
        class="MediaList--Upload MediaList--Upload-Image"
      >
        <button
          v-if="!newImage"
          class="MediaList--Button"
          data-ux="Explore_OrganizationPublicPage_EditMode_Carousel_UploadNewImage"
          @click="$emit('show-modal')"
        >
          <HaIcon :icon="faCloudArrowUp" />
          {{ $t('organization.carousel.uploadImage') }}
          <span class="visually-hidden">
            {{ $t('organization.carousel.uploadImageLabel') }}
          </span>
        </button>
        <img
          v-else
          :src="newImageUrl"
          alt=""
          loading="lazy"
        />
        <HaValidatorInput
          v-slot="{ validated, errors }"
          name="imageLegend"
          :model-value="imageLegend"
          rules=""
          slim
        >
          <haFormField :is-valid="validated ? valid : null">
            <label
              for="newImageLegend"
              class="visually-hidden"
            >
              {{ $t('organization.carousel.legendLabel') }}
            </label>
            <HaInput
              id="newImageLegend"
              v-model="imageLegend"
              name="imageLegend"
              :max-length="100"
              :is-valid="validated ? valid : null"
              :placeholder="
                $t('organization.carousel.legendPlaceholder')
              "
            />
            <template #error>
              {{ errors[0] }}
            </template>
          </haFormField>
        </HaValidatorInput>
        <div class="MediaList--Actions">
          <ha-button
            color="basic"
            variant="flat"
            data-ux="Explore_OrganizationPublicPage_EditMode_Carousel_CancelNewImage"
            @click="cancel()"
          >
            {{ $t('button.cancel') }}
          </ha-button>
          <ha-button
            :loading="isLoading"
            :disabled="!valid || !newImage || isLoading"
            data-ux="Explore_OrganizationPublicPage_EditMode_Carousel_SaveNewImage"
            @click="save()"
          >
            {{ $t('button.add') }}
          </ha-button>
        </div>
      </div>
      <button
        v-else
        class="MediaList--Button"
        data-ux="Explore_OrganizationPublicPage_EditMode_Carousel_AddNewImage"
        @click="showImageUploader = true"
      >
        <HaIcon :icon="faCamera" />
        {{ $t('organization.carousel.addImage') }}
      </button>
    </HaValidatorForm>
  </li>
</template>

<script>
import {
  faCloudArrowUp,
  faCamera
} from '@fortawesome/pro-solid-svg-icons'
import {
  HaButton,
  HaFormField,
  HaInput,
  HaValidatorInput,
  HaValidatorForm,
  useTracking,
  HaIcon,
  useNotifications
} from '@ha/components-v3'
import { nextTick } from 'vue'
import { useOrganizationStore } from '~/store/organization.store'

export default {
  name: 'AddImage',
  components: {
    HaValidatorForm,
    HaValidatorInput,
    HaButton,
    HaFormField,
    HaInput,
    HaIcon
  },
  setup() {
    const { pushNotification } = useNotifications()
    const { tracking } = useTracking()
    const organizationStore = useOrganizationStore()

    return { pushNotification, tracking, organizationStore }
  },
  data() {
    return {
      isLoading: false,
      showImageUploader: false,
      newImageUrl: null,
      imageLegend: '',
      faCloudArrowUp,
      faCamera
    }
  },
  computed: {
    organization() {
      return this.organizationStore.organization
    },
    newImage() {
      return this.organizationStore.newImage
    }
  },
  watch: {
    newImage: {
      deep: true,
      handler(value) {
        if (typeof window !== 'undefined') {
          if (this.newImageUrl) {
            window.URL.revokeObjectURL(this.previewURL)
            this.newImageUrl = null
            this.showImageUploader = false
          }
          if (value) {
            nextTick(() => {
              this.newImageUrl = window.URL.createObjectURL(value.image)
            })
          }
        }
      }
    }
  },
  methods: {
    cancel() {
      this.imageLegend = ''
      this.newImageUrl = null
      this.showImageUploader = false

      this.organizationStore.resetNewImage()
    },
    save() {
      this.isLoading = true

      this.organizationStore
        .addImage({
          slug: this.organization.organizationSlug,
          file: this.newImage,
          caption: this.imageLegend
        })
        .then(() => {
          this.imageLegend = ''

          this.tracking.track('Organization Page Updated', {
            organization_slug: this.organization.organizationSlug,
            update_type: ['Ajout Image']
          })

          this.pushNotification({
            type: 'success',
            title: this.$t('organization.carousel.add.image.title'),
            body: this.$t('organization.carousel.add.image.body'),
            timeout: 5000
          })
        })
        .catch((err) => {
          const status = err?.response.status

          this.errorUpload = this.$t(`error.code.${status}.message`)
          this.file = null

          this.pushNotification({
            type: 'danger',
            title: this.$t('error.upload'),
            body: this.$t(`error.code.${status}.message`),
            timeout: 5000
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
