<template>
  <div>
    <img
    v-if="isDisplayed"
      :src="imgSrc"
      :alt="alt"
      :data-test-id="dataTestId"
      @error="setError"
    >
    <!-- fallback -->
    <slot v-else />
  </div>
</template>

<script setup>
import { ref, computed } from '#imports'
import { resizedSrc } from '@/helpers/utils'

const props = defineProps({
  size: {
    type: Object,
    default: null
  },
  src: {
    type: String,
    default: ''
  },
  alt: {
    type: String,
    default: ''
  },
  dataTestId: {
    type: String,
    default: ''
  }
})

const hasError = ref(false)
// const imgSrc = ref(props.src)

const isDisplayed = computed(() => {
  return hasImage.value &&  !hasError.value
})
const hasImage = computed(() => {
  return !!props.src
})

const imgSrc = computed(() => {
  if (props.size) {
    return resizedSrc(props.src, props.size)
  }
  return props.src
})

const setError = () => {
  hasError.value= true
}


</script>
